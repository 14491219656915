<template>
  <div class="template-pilot-profile">
    <div class="row">
      <div class="col-sm-12">
        <div class="card bg-primary">
          <div class="card-body profile-user-box">
            <div class="row">
              <div class="col-sm-8">
                <div v-if="propDataReady && !$apollo.queries.flights.loading">
                  <info :reserveData="reserveData" :flightData="flights" />
                </div>
              </div>
              <!-- end col-->
              <div class="col-sm-4">
                <div class="text-center mt-sm-0 mt-3 text-sm-right">
                  <router-link to="/admin/reserve">
                    <button type="button" class="btn btn-light mr-1">
                      <i class="mdi mdi-arrow-left mr-1"></i> Back
                    </button>
                  </router-link>
                  <button
                    type="button"
                    class="btn btn-light ml-1"
                    @click="editPilotProfile()"
                  >
                    <i class="mdi mdi-account-edit mr-1"></i> Edit Profile
                  </button>
                </div>
              </div>
              <!-- end col-->
            </div>
            <!-- end row -->
          </div>
          <!-- end card-body/ profile-user-box-->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div v-if="propDataReady">
          <card :reserveData="reserveData" />
        </div>
      </div>
      <div class="col-lg-8">
        <div v-if="!$apollo.queries.flights.loading">
          <monthlyFlight :flightData="flights" />
        </div>
        <div v-if="propDataReady">
          <reserveFlights :flights="flights" />
        </div>
      </div>
    </div>
    <div>
      <div class="col-lg-12"></div>
    </div>
  </div>
</template>

<script>
import info from "@/components/subcomponents/reserve/reserveInfo.vue";
import { GET_RESERVE_PK, GET_RESERVE_FLIGHTS } from "@/graphql/queries/queries";
import card from "@/components/subcomponents/reserve/card";
import reserveFlights from "@/components/subcomponents/reserve/reserveFlight.vue";
import monthlyFlight from "@/components/composable/MonthlyFlight.vue";

export default {
  name: "reserveProfile",
  components: { info, card, reserveFlights, monthlyFlight },
  data() {
    return {
      propDataReady: false,
      reserveId: this.$route.params.reserveID,
      reserveData: {},
    };
  },
  methods: {
    editPilotProfile() {
      this.$router.push({
        name: "reserveAdd",
        params: { reserveID: this.reserveId },
      });
    },
  },
  apollo: {
    reserveData: {
      // graphql query
      query: GET_RESERVE_PK,
      variables() {
        // Use vue reactive properties here
        return {
          id: this.reserveId,
        };
      },
      update(data) {
        this.propDataReady = true;
        return data.reserves_by_pk;
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    flights: {
      query: GET_RESERVE_FLIGHTS,
      variables() {
        return {
          reserve_id: this.$route.params.reserveID,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.template-pilot-profile {
  padding-top: 24px;
}
</style>
